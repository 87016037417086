import React, { useState } from "react";
import { Field, Form, Formik, FormikProps } from "formik";
import { RenderNumber } from "../../../Forms/InputComponents/Number";
import { RenderDateTime } from "../../../Forms/InputComponents/DateTime";
import { RenderSelect } from "../../../Forms/InputComponents/Select";
import CourseSchedule from "./CourseSchedule";
import { CourseMode } from "../../../Types/DictionaryItem";
import SelectProduct from "../../../Forms/InputComponents/ProductSelect";
import { CurrentProductVersionItem } from "../../ProductsNew/Types/CurrentProductVersionType";
import SelectCourseSpecialist from "../../../Forms/InputComponents/CourseSpecialistSelect";
import WEEK_DAYS from "../../../Enums/WeeksDays";
import courseScheduleScheme from "../../../Enums/CourseScheduleScheme";
import { TranslationFunction } from "react-i18next";
import CancelCourse from "./CancelCourse";
import validate from "../Validators/CourseSettingsValidator";
import { get } from "lodash";
import dateHelper from "../../../Helpers/DateHelper";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import { AlertTriangle } from "react-feather";
import { RenderMultiselect } from "../../../Forms/InputComponents/MultiSelect";
import {
  CourseSettingsFormProps,
  CourseSettingsFormValues,
} from "../Types/CourseSettingsForm";

const CourseSettingsForm: React.FunctionComponent<CourseSettingsFormProps> = ({
  t,
  handleSubmit,
  cities,
  modes,
  products,
  courseSpecialists,
  course,
  isNew,
  isCancel,
  isEnd,
  isProductsLoading,
  isCourseSpecialistsLoading,
  fetchProductsData,
  fetchCourseSpecialists,
  onShowMoreProductsResults,
  onShowMoreCourseSpecialistsResults,
  onCancelCourse,
  mentors,
  fetchMentorsForCourse,
}): React.ReactElement => {
  const [warningConfirmCallback, setWarningConfirmCallback] = useState<
    () => void
  >();
  const [warningModalShow, setWarningModalShow] = useState(false);

  const shouldDisabled = isCancel || isEnd;
  const fieldClasses = {
    containerClassName: "col-md-4 col-sm-4",
    fieldClassName: "col-xs-12",
  };

  const getInitialValues = (): CourseSettingsFormValues => {
    let signatureCounter: number | null = null;

    if (course.signature) {
      const [counterFromSignature] = course.signature.split("_").reverse();
      signatureCounter = Number(counterFromSignature) || null;
    }

    return isNew
      ? {
          city: undefined,
          numberOfPlaces: undefined,
          startDate: undefined,
          publicityDate: undefined,
          courseSchedule: {
            startHour: "09:00",
            endHour: "17:00",
            days: [],
            courseMode: null,
          },
          postponeCounter: undefined,
          signatureCounter: undefined,
          product: [],
          courseSpecialist: [],
          endDate: undefined,
          mentors: [],
        }
      : {
          city: course.city,
          numberOfPlaces: course.numberOfPlaces,
          startDate: course.startDate,
          publicityDate: course.publicityDate,
          courseSchedule: {
            days: course.courseSchedule.days.map(day => ({
              id: day,
              name: t(WEEK_DAYS.SHORT[day - 1]),
            })),
            startHour: course.courseSchedule.startHour,
            endHour: course.courseSchedule.endHour,
            courseMode: modes.find(
              mode => mode.id === course.courseSchedule.courseMode,
            ),
          },
          postponeCounter: course.postponeCounter,
          signatureCounter: signatureCounter,
          product: course.product ? [course.product] : [],
          courseSpecialist: course.courseSpecialist
            ? [
                {
                  ...course.courseSpecialist,
                  id: course.courseSpecialist.personId,
                },
              ]
            : [],
          endDate: course.endDate,
          mentors: course.mentors || [],
        };
  };

  const getDaysOfWeekWithTranslate = (translate: TranslationFunction) =>
    WEEK_DAYS.SHORT.map((weekDay, index) => ({
      name: translate(weekDay),
      id: index + 1,
    }));

  const warningModalProvider = (callbackConfirm: () => void): void => {
    setWarningConfirmCallback(callbackConfirm);
    setWarningModalShow(true);
  };

  const onWarningConfirm = (): void => {
    warningConfirmCallback();
    setWarningConfirmCallback(null);
    setWarningModalShow(false);
  };

  const onWarningCancel = (): void => {
    setWarningConfirmCallback(null);
    setWarningModalShow(false);
  };

  const handleChangeProduct = (
    fieldName: string,
    products: Array<CurrentProductVersionItem>,
    formProps: FormikProps<CourseSettingsFormValues>,
  ) => {
    const { setFieldValue } = formProps;
    const [product] = products;

    const setProductValue = (): void => {
      setFieldValue(fieldName, products);
      if (product) {
        fetchMentorsForCourse(product.id);
      }
    };

    if (isNew) {
      setProductValue();
    } else {
      warningModalProvider(() => setProductValue);
    }
  };

  const handleChangeCourseMode = (
    courseMode: CourseMode,
    formProps: FormikProps<CourseSettingsFormValues>,
  ): void => {
    const { setFieldValue, submitForm } = formProps;
    const daysOfWeekShort = getDaysOfWeekWithTranslate(t);
    const daysChecked = courseScheduleScheme[courseMode.id].days.map(dayId =>
      daysOfWeekShort.find(dayOfWeek => dayOfWeek.id === dayId.id),
    );

    if (isNew) {
      setFieldValue("courseSchedule.courseMode", courseMode);
      setFieldValue("courseSchedule.days", daysChecked);
    } else {
      warningModalProvider(() => () => {
        setFieldValue("courseSchedule.courseMode", courseMode);
        setFieldValue("courseSchedule.days", daysChecked);
        submitForm();
      });
    }
  };

  const handleStartDateChange = (
    date: Date,
    formProps: FormikProps<CourseSettingsFormValues>,
  ): void => {
    const { setFieldValue, submitForm } = formProps;
    if (isNew) {
      setFieldValue("startDate", dateHelper(date).getFormatted());
    } else {
      warningModalProvider(() => () => {
        setFieldValue("startDate", dateHelper(date).getFormatted());
        submitForm();
      });
    }
  };

  const mappedMentors = mentors.map(mentor => ({
    ...mentor,
    personId: mentor.id,
  }));

  return (
    <div id="course-settings-form-container">
      <Formik
        initialValues={getInitialValues()}
        validate={validate}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {formProps => (
          <Form>
            <div className="row">
              <Field
                name="product"
                id="product"
                label={t("Product")}
                component={SelectProduct}
                products={products}
                fetchData={fetchProductsData}
                onShowMoreResults={onShowMoreProductsResults}
                isLoading={isProductsLoading}
                disabled={shouldDisabled}
                required
                placeholder={t("Select the product")}
                under={fieldClasses}
                onProductChange={products =>
                  handleChangeProduct("product", products, formProps)
                }
              />

              <Field
                name="numberOfPlaces"
                id="numberOfPlaces"
                label={t("Number of places")}
                component={RenderNumber}
                disabled={shouldDisabled}
                under={fieldClasses}
              />

              <Field
                name="publicityDate"
                id="publicityDate"
                label={t("Publication")}
                component={RenderDateTime}
                disabled={shouldDisabled}
                time={false}
                required
                under={fieldClasses}
              />
            </div>
            <div className="row">
              <CourseSchedule
                t={t}
                modes={modes}
                disabled={shouldDisabled}
                daysError={get(formProps, "errors.courseSchedule.days", "")}
                onChangeCourseMode={mode =>
                  handleChangeCourseMode(mode, formProps)
                }
              />

              <Field
                name="city"
                id="city"
                label={t("City")}
                component={RenderSelect}
                disabled={shouldDisabled}
                required
                dropdownConfig={{
                  data: cities,
                  textField: "name",
                  valueField: "id",
                  filter: "contains",
                }}
                under={fieldClasses}
              />

              <Field
                name="startDate"
                id="startDate"
                label={t("Start date")}
                component={RenderDateTime}
                disabled={shouldDisabled}
                time={false}
                required
                under={fieldClasses}
                onChange={date => handleStartDateChange(date, formProps)}
              />

              {!isNew && (
                <div className="col-sm-4 col-md-4 col-sm-offset-4 col-md-offset-4">
                  <Field
                    name="signatureCounter"
                    id="signatureCounter"
                    label={t("Course number")}
                    component={RenderNumber}
                    parse={string => parseInt(string)}
                    precision={1}
                    disabled={shouldDisabled}
                    under={{
                      containerClassName: "col-md-6 col-sm-6 padding__l0",
                      fieldClassName: "col-xs-12",
                    }}
                  />

                  <Field
                    name="postponeCounter"
                    id="postponeCounter"
                    label={t("Postpone counter")}
                    component={RenderNumber}
                    parse={string => parseInt(string)}
                    precision={1}
                    disabled={shouldDisabled}
                    under={{
                      containerClassName: "col-md-6 col-sm-6 padding__l0",
                      fieldClassName: "col-xs-12",
                    }}
                  />
                </div>
              )}

              <div className="row">
                <div className="col-xs-12">
                  <Field
                    id="mentors"
                    name="mentors"
                    label={t("Mentors")}
                    component={RenderMultiselect}
                    disabled={shouldDisabled || !formProps.values.product}
                    dropdownConfig={{
                      data: mappedMentors,
                      textField: mentor =>
                        mentor ? `${mentor.name} ${mentor.surname}` : null,
                      valueField: "id",
                      onClearCallback: () =>
                        formProps.setFieldValue("mentors", []),
                    }}
                    under={fieldClasses}
                  />

                  <Field
                    name="courseSpecialist"
                    id="courseSpecialist"
                    component={SelectCourseSpecialist}
                    label={t("Course Specialist")}
                    courseSpecialists={courseSpecialists}
                    fetchData={fetchCourseSpecialists}
                    onShowMoreResults={onShowMoreCourseSpecialistsResults}
                    isLoading={isCourseSpecialistsLoading}
                    disabled={shouldDisabled}
                    placeholder={t("Select")}
                    under={fieldClasses}
                  />

                  {!isNew && (
                    <Field
                      name="endDate"
                      label={t("End date")}
                      component={RenderDateTime}
                      under={fieldClasses}
                      readOnly={true}
                      time={false}
                    />
                  )}
                </div>
              </div>

              <div id="course-settings-form-actions" className="row content">
                <div className="col-xs-12">
                  <div className="col-md-6 col-sm-12 text-left">
                    <button
                      className="btn btn-dark btn-margin"
                      type="submit"
                      disabled={shouldDisabled}
                    >
                      {t("Save")}
                    </button>
                  </div>
                  <div className="col-md-3 col-md-offset-3 col-sm-12 text-right">
                    {!isNew && (
                      <CancelCourse
                        t={t}
                        disabled={isCancel || isEnd}
                        handleCancelCourse={onCancelCourse}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <CustomModal
        isOpen={warningModalShow}
        title={t("Change schedule")}
        onRequestClose={onWarningCancel}
      >
        <div className="row">
          <div className="col-xs-1">
            <AlertTriangle size={70} />
          </div>
          <div className="col-xs-10 warning-alert">{t("WARNING!")}</div>
          <div className="col-xs-12 padding__b20 warning-alert__small warning-alert__small--red">
            {t(
              "The field's change will result in recalculation of the current schedule. Changes for specific lectures will be deleted",
            )}
          </div>
          <div className="col-xs-12 padding__b20">
            {t("Are You sure You want to change this field?")}
          </div>
        </div>
        <div>
          <button
            id="warningConfirmButton"
            type="button"
            className="btn btn-dark"
            onClick={onWarningConfirm}
          >
            {t("Yes")}
          </button>
          <button
            id="warningCancelButton"
            type="button"
            className="btn btn-regular"
            onClick={onWarningCancel}
          >
            {t("No")}
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default CourseSettingsForm;
