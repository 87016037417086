const baseUrl = window.location.origin;
const API_URL = "https://hub.coderslabkosova.com/api";

export default {
  API_URL,
  baseUrl,
  googleClientId:
    "860288514084-g0kl8htjnn9o3e41pppbs0r3jh6qg16s.apps.googleusercontent.com",
  googleClientSecret: "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",
  sentryDsn: "https://75d514efedbb4d0a8721b597749fdbf1@o203382.ingest.sentry.io/4504553453453312",
  selectedLang: "en",
};
